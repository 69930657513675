<template>
    <div
            class="cs-select"
            :style="{ '--bd-color': bdColor, '--height': height, 'border': border }"
    >
        <slot></slot>
        <i class="icon el-icon-caret-bottom" :style="{'--cs-select-icon': iWidth}" @click="prevBtn"></i>
    </div>
</template>

<script>
    export default {
        props: {
            bdColor: String,
            height: String,
            border: String,
            iWidth: {
                type: String,
                default: '26px'
            }
        },
        mounted() {
            let iList = document.getElementsByClassName('el-icon-caret-bottom');
            for (var i = 0; i < iList.length; i++) {
                if(!iList[i].getAttribute('id')){
                    iList[i].setAttribute('id',`${this.randomC()}`);
                }

            }
        },
        methods: {
            prevBtn(e) {
                var v_id = e.target.id;
                $(`#${v_id}`).prev().click();
                $(`#${v_id}`).prev().children()[0].focus();
            },
            randomC() {
                let str = "";
                let ranStr = 'abdfghjkispu'
                for(var i = 0;i < 10;i++){
                    let index =Math.floor(Math.random() * 10);
                    str += Math.floor(Math.random()*12)+ ranStr[index];
                }
                return str
            }
        },
    };
</script>


<style lang="stylus" scoped>
    .cs-select
        display inline-flex
        flex-flow row nowrap
        justify-content space-between
        align-items center
        border 1px solid var(--bd-color, #979797)
        height var(--height, 30px)
        overflow hidden
        //border-radius 1px
        vertical-align middle
        border-radius 4px

        .cs-input
            height var(--height, 30px)
            flex 0 0 auto
            border none
            outline none
            text-indent 10px

            &::placeholder
                color #999

        select
            border none
            background #fff
            padding-right 26px
            margin-right -26px
            outline none
            color #000
            flex 1 1 auto

        .icon
            width var(--cs-select-icon, 26px)
            min-width 26px
            max-width 36px
            text-align center
            border-left 1px solid var(--bd-color, #979797)
            height 100%
            line-height var(--icon-line-height, var(--height, 30px))
            font-size 15px
            position relative
            background #fff
            z-index 1

        .el-date-editor
            height 30px
            //vertical-align middle

            input
                height 30px
                border 0

            .el-input__prefix, .el-input__suffix
                height 30px

                .el-input__icon
                    line-height 30px
</style>
