
<template>
    <div class="employeeAccess-container">
        <div class="filter-panel" >
            <CSSelect  style="width: 200px">
                <el-date-picker
                        v-model="startDate"
                        type="datetime"
                        format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm"
                        placeholder="开始时间"
                        prefix-icon="el-icon-time"
                        :editable="false"
                >
                </el-date-picker>
            </CSSelect>
            <span style="margin-left: -10px;margin-right: 10px;">-</span>
            <CSSelect style="width: 200px" >
                <el-date-picker
                        v-model="endDate"
                        type="datetime"
                        format="yyyy-MM-dd HH:mm"
                        value-format="yyyy-MM-dd HH:mm"
                        placeholder="结束时间"
                        prefix-icon="el-icon-time"
                        :editable="false"
                >
                </el-date-picker>
            </CSSelect>
            <input
                    type="text"
                    class="cs-input"
                    style="width: 220px"
                    placeholder="搜索姓名/手机号"
                    v-model="search"
            />
            <CSSelect style="margin-right: 20px; padding-left: 5px;" class="select-pan">
                <el-select v-model="passMethod" placeholder="请选择">
                    <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </CSSelect>
            <CSSelect style="margin-right: 20px; padding-left: 5px;" class="select-pan">
                <el-select v-model="dire" placeholder="请选择" >
                    <el-option
                            v-for="item in direction"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </CSSelect>
            <button
                    class="btn btn-primary"
                    style="margin-left: 10px;padding-left: 10px;padding-right: 10px"
                    @click="searchData()"
            >
                查询
            </button>
        </div>
        <div class="result-panel">
            <CSTable>
                <template v-slot:header>
                    <div class="table-header-panel"
                    >
                        <div>
                            <svg
                                    class="icon"
                                    aria-hidden="true"
                                    style="font-size: 16px; margin-right: 8px"
                            >
                                <use xlink:href="#icon-menua-zu92"></use>
                            </svg>
                            只显示已注册且所属企业为本公司的人员。
                        </div>
                    </div>
                </template>
                <template v-slot:thead>
                    <tr>
                        <th>通行时间</th>
                        <th>姓名</th>
                        <th>性别</th>
                        <th>手机号</th>
                        <th>身份证号</th>
                        <th>通行方式</th>
                        <th>方向</th>
                        <th>体温</th>
                    </tr>
                </template>
                <template v-slot:tbody>
                    <tr v-for="item in recordList" :key="item.idCard">
                        <td>{{item.passTime}}</td>
                        <td>{{item.userName}}</td>
                        <td>{{ genderObj[item.gender]}}</td>
                        <td>{{item.phone}}</td>
                        <td>{{item.idCard?item.idCard:'-'}}</td>

                        <td>{{option[item.passMethod - 1]}}</td>
                        <td>{{item.direction?directionArr[item.direction - 1]:'-'}}</td>
                        <td>
                           {{item.bodyTemperature?item.bodyTemperature+'℃':'-'}}
                        </td>
                    </tr>
                </template>
            </CSTable>
            <Pagination/>
        </div>
    </div>
</template>

<script>
    import CSSelect from "@/components/common/CSSelect";
    import {queryPassingLogByLesseeIdUrl}from '@/requestUrl';
    import Pagination from "@/components/Pagination";
    import CSTable from "@/components/common/CSTable";
    export  default {
        name:"employeeAccess",
        data(){
            return{
                search:'',
                startDate: dayjs().subtract(1, 'month').format("YYYY-MM-DD") + ' 00:00',
                endDate: dayjs().format("YYYY-MM-DD") + ' 23:59',
                passMethod:"",
                dire:"",//通行方向
                recordList:[],
                genderObj : {
                    1:'男',
                    2:'女',
                    0:'未知'
                },
                options: [{
                    value: '',
                    label: '全部通行方式'
                },{
                    value: '1',
                    label: '扫码'
                }, {
                    value: '2',
                    label: '刷脸'
                }, {
                    value: '3',
                    label: '刷卡'
                }],
                direction:[{
                    value: '',
                    label: '方向不限'
                },{
                    value: '1',
                    label: '进'
                },{
                    value: '2',
                    label: '出'
                }],
                option:['扫码','刷脸','刷卡'],
                directionArr:['进','出']
            }
        },
        components:{
            CSSelect,
            Pagination,
            CSTable
        },
        created() {
            let item = this.$route.query.item;
            if(item){
                this.search = item.realName;
            }

            this.searchData();
            window.addEventListener('keydown',this.searchDataEnd);
        },
        beforeDestroy() {
            window.removeEventListener('keydown',this.searchDataEnd)
        },
        deactivated() {
            window.removeEventListener('keydown',this.searchDataEnd)
        },
        mounted() {
            this.$vc.on(this.$route.path, "pagination", "page_event", (pageNo) => {
                this.searchData(pageNo);
            });
        },
        methods:{
            //回车查询列表
            searchDataEnd(e){
                if(e.keyCode == 13){
                    this.searchData();
                }
            },
            searchData(pageNo = 1,pageSize = 10){
                this.$fly.post(queryPassingLogByLesseeIdUrl,{
                    "lesseeId":  this.$vc.getCurrentStaffInfo().lesseeId, //企业id
                    "startTime":this.startDate,  //起始时间
                    "endTime":this.endDate,     //结束时间
                    "search":this.search,      //搜索框内容
                    "passMethod":this.passMethod,  //通行方式
                    "direction":this.dire,   //通行方向
                    pageSize,   //页面大小
                    pageNo     //页码
                }).then(res => {
                    if(res.code != 0){
                        return
                    }
                    if (pageNo == 1) {
                        this.$vc.emit(this.$route.path, "pagination", "init", {
                            total: res.data.total,
                            currentPage: pageNo,
                            pageSize: pageSize
                        });
                    }
                    this.recordList = res.data.datas;
                })
            },

        }
    }
</script>

<style lang="stylus" scoped>
.select-pan{
    width:150px
}
</style>