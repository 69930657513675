var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "cs-select",
      style: {
        "--bd-color": _vm.bdColor,
        "--height": _vm.height,
        border: _vm.border,
      },
    },
    [
      _vm._t("default"),
      _c("i", {
        staticClass: "icon el-icon-caret-bottom",
        style: { "--cs-select-icon": _vm.iWidth },
        on: { click: _vm.prevBtn },
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }